/*
 * Copyright 2021 EPAM Systems, Inc. (https://www.epam.com/)
 * SPDX-License-Identifier: Apache-2.0
 */
.confirmation {
  top: 58%;
  position: absolute;
  width: 28rem;
  left: 50%;
  line-height: 1.5;
  transform: translate(-50%, -50%);
  color: var(--white);
  border-radius: 15px;
  background-color: var(--dark-gray);
  transition: 1s ease-out;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.close_body {
  display: flex-block;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1003;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  z-index: 1004;
  position: absolute;
  width: 93%;
  top: 9vh;
  left: 49%;
  margin: 0 5px;
  transform: translateX(-50%);
  color: var(--white);
  border-radius: 5px;
  background-color: var(--dark-gray);
  transition: 1s ease-out;
  display: flex;
  flex-direction: column;
}

.modalWindowRange {
  margin-top: 2rem;
}

.header,
.body,
.footer {
  padding: 15px 20px;
}

.header,
.footer {
  display: flex;
  align-items: center;
}

.header {
  box-sizing: content-box;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray);
  min-height: 36px;
}

.footer {
  justify-content: right;
  border-top: 1px solid var(--gray);
}

.body {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: visible;
}

.title {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.close:hover svg,
.close:focus svg {
  fill: var(--gray);
}

.cards {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto auto;
}

.card {
  border-radius: 4px;
  overflow: hidden;
}

.card:hover,
.card:focus {
  box-shadow: 0px 2px 10px 4px rgba(255, 255, 255, 0.2);
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.row label {
  font-size: 14px;
}

.row *:not(:first-child) {
  margin-top: 10px;
}

.label {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.input_label_left {
  margin-right: 5px;
}

.input {
  flex-grow: 1;
  border: 1px solid var(--dark-gray);
  background-color: var(--gray);
  border-radius: 5px;
  padding: 8px;
  font: inherit;
  color: inherit;
}

.input::placeholder {
  color: inherit;
  font: inherit;
}

.input:focus {
  outline-color: var(--light);
}

.button {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid var(--light);
  padding: 5px 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 16px;
}

.button:hover {
  background-color: var(--light);
  color: var(--dark-gray);
}

.text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.5;
  color: var(--white);
}

.text_center {
  text-align: center;
}

.helperText {
  font-size: 14px;
  margin-top: 8px;
}

@media screen and (min-width: 768px) {
  .modal {
    width: 600px;
  }

  .cards {
    grid-template-columns: repeat(auto-fill, 128px);
  }

  .row label {
    font-size: 16px;
  }
}
.body_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label_text {
  margin: 5px 0;
}

.invalid {
  border-color: red;
  box-shadow: 0 0 5px red;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.select_file_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20xp;
  padding: 30px;
}

.select_file_row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 30px;
}
